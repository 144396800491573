@if (notProd) {
  <kin-drawer #debugTray width="wide" contained="true">
    <h3 class="flex flex-wrap items-center gap-100" slot="header">
      <ng-icon name="lucideBug" size="1.5em" class="flex-shrink-0 self-start" aria-hidden="true"></ng-icon>
      Debug Console
    </h3>

    <p class="card m-b-200">CIR ID: {{ quoteStore.cirId() }} | Quoting: {{ userStore.isQuoting() }}</p>

    <kin-tabs>
      <kin-tab slot="tablist">Basic</kin-tab>
      <kin-tab slot="tablist">Quote State</kin-tab>
      <kin-tab slot="tablist">User State</kin-tab>
      <kin-tab slot="tablist">Token</kin-tab>
      <kin-tab slot="tablist">Policy State</kin-tab>
      <kin-tab slot="tablist">Form Options</kin-tab>
      <kin-tab-panel>
        <app-debug-nav-to-form class="p-300"></app-debug-nav-to-form>
        <div class="p-300">
          <h4 class="pb-200">Helpful Links</h4>
          <ul class="list-inside list-disc">
            <li><a class="link" target="_blank" ref="noopener" href="https://kininsurance.atlassian.net/wiki/spaces/ENG/pages/63143970/Test+Addresses">List of Test Addresses</a></li>
            <li class="link cursor-pointer" (click)="clearStorage()">Clear Browser Storage</li>
          </ul>
        </div>
      </kin-tab-panel>

      <kin-tab-panel>
        <div class="p-300">
          <h4 class="pb-200">Current State</h4>
          <app-debug-quote-draft-state [readonly]="true"></app-debug-quote-draft-state>
        </div>
        <div class="p-300">
          <app-debug-quote-draft-state></app-debug-quote-draft-state>
        </div>
      </kin-tab-panel>

      <kin-tab-panel>
        <div class="p-300">
          <h4 class="pb-200">User State</h4>
          <app-debug-user-state [readonly]="true"></app-debug-user-state>
        </div>
        <div class="p-300">
          <app-debug-user-state></app-debug-user-state>
        </div>
      </kin-tab-panel>

      <kin-tab-panel>
        <div class="p-300">
          <h4 class="pb-200">Token</h4>
          <app-debug-resume></app-debug-resume>
        </div>
      </kin-tab-panel>
      <kin-tab-panel>
        <app-debug-policy-state></app-debug-policy-state>
      </kin-tab-panel>

      <kin-tab-panel>
        <app-debug-form-options></app-debug-form-options>
      </kin-tab-panel>
    </kin-tabs>
  </kin-drawer>
}
