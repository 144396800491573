import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NgKLoggerService } from '@kin/ngk-logger';
import { Observable } from 'rxjs';
import { QuoteDraftStore } from '../../../global-store/quote-draft';
import { AppContext } from '../../../app-config';

/**
 * Legacy (v1) Quote API Interceptor
 * used to attach the auth header to the request
 */
@Injectable()
export class LegacyQuotesApiInterceptor implements HttpInterceptor {
  // get context from the App Context decorator
  @AppContext() private appContext!: AppContext;
  // inject the store
  store = inject(QuoteDraftStore);
  logger = inject(NgKLoggerService);

  /**
   * Interceptor for adding authorization and tracking headers
   */
  intercept(orgRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const urlRoots = [this.appContext.apiRoot.quoteV1]; // ensure we only add headers for v1 API request
    const urlsToSkip: string[] = [`${urlRoots[0]}/marketing_leads_for_new_states`]; // exclude the address request since that is also where we get the JWT
    const optionalUrlsToSkip = [`${urlRoots[0]}/addresses`]; // if there is a token, include it, otherwise it is OK sending without one

    const shouldAddToken: boolean = LegacyQuotesApiInterceptor.checkUrl(urlRoots, urlsToSkip, orgRequest.url);
    const isOptional: boolean = LegacyQuotesApiInterceptor.checkUrl(urlRoots, optionalUrlsToSkip, orgRequest.url);

    // only add headers for selected API requests patterns
    if (shouldAddToken) {
      // get the auth token
      const authToken = this.store.draftToken();
      // if there is a token, use it, otherwise do not append the header and log an error
      if (authToken) {
        // create the new request and add auth header
        const updatedRequest = LegacyQuotesApiInterceptor.addAuthHeader(authToken, orgRequest);
        return next.handle(updatedRequest);
      }
      if (!isOptional) {
        // log error around attempt to make a request that had a bad or missing JWT
        this.logger.error({ context: 'LegacyQuotesApiInterceptor', message: `token missing for request: ${orgRequest}`, priority: 'P4' });
      }
    }

    // did not meet criteria to attach header, return the original request unmodified
    return next.handle(orgRequest);
  }

  /**
   * Clones the passed HTTP Req and adds an auth header
   */
  static addAuthHeader(token: string, orgRequest: HttpRequest<unknown>): HttpRequest<unknown> {
    return orgRequest.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }

  /**
   * ensures we only add headers for urls we care about
   */
  static checkUrl(rootUrls: string[], urlsToSkip: string[], reqUrl: string): boolean {
    // loop over root urls
    const matchedRootUrls = rootUrls.filter((rootUrl) => reqUrl.includes(rootUrl));
    const matchedSkipUrls = urlsToSkip.includes(reqUrl);
    if (matchedRootUrls.length > 0 && !matchedSkipUrls) {
      return true;
    }
    return false;
  }
}
